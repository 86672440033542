<template>
  <main class="home">
    <section class="under-nav">
      <CarouselContainer :carouselImgs="carouselImgs" :ratio="carouselRatio" :socials="true" :onMobile="false" />
      <HeroLinks v-if="!isMobile" :links="links"/>
    </section>
    <AomoriApple :img="aomoriAppleSec"/>
    <AboutAomori :img="aboutAomoriImg"/>
    <AomoriStores :stores="stores"/>
    <TestimonialSec :testimonials="testimonials" :articles="articles"/>
  </main>
</template>

<script>
// @ is an alias to /src
import sourceData from '@/data.json';
import CarouselContainer from '@/components/CarouselContainer.vue';
import HeroLinks from '@/components/home/HeroLinks.vue';
import AomoriApple from '@/components/home/AomoriApple.vue';
import AboutAomori from '@/components/home/AboutAomori.vue';
import AomoriStores from '@/components/home/AomoriStores.vue';
import TestimonialSec from '@/components/home/TestimonialSec.vue';

export default {
  name: 'HomeView',
  components: {
    CarouselContainer,
    HeroLinks,
    AomoriApple,
    AboutAomori,
    AomoriStores,
    TestimonialSec,
  },
  data() {
    return{
      isMobile: false,
      carouselImgs: sourceData.homeview.carouselImgs,
      carouselRatio: sourceData.homeview.carouselRatio,
      links: sourceData.homeview.links,
      aomoriAppleSec: sourceData.homeview.aomoriAppleSec,
      aboutAomoriImg: sourceData.homeview.aboutAomoriImg,
      stores: sourceData.homeview.stores,
      testimonials: sourceData.homeview.testimonials,
      articles: sourceData.homeview.articles,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

  section.under-nav{
    padding-bottom: 0;
  }

</style>
