<template>
  <section class="container-fluid">
    <div class="container-L">
      <h1>TÁO AOMORI, NHẬT BẢN</h1>
      <div class="content-bottom">
        <div class="content-text">
          <p>Táo Aomori, Nhật Bản được xem là một trong những loại táo ngon nhất thế giới.</p>
          <p>Táo Nhật xưa nay vốn nổi tiếng là cao cấp. Trong những vùng trồng táo lớn của Nhật, nổi bật nhất là tỉnh Aomori nằm ở phía bắc - nơi được mệnh danh là quê hương của các loại táo Nhật với lịch sử hơn 140 năm. Hàng năm, tỉnh Aomori cung cấp đến 60% sản lượng táo cho cả Nhật Bản. Sản lượng năm 2020 đến 463.300 tấn.</p>
          <p>Hiện tỉnh Aomori có 50 giống táo ngon, trong đó nổi tiếng nhất và có sản lượng cao nhất là táo Fuji, chiếm khoảng 50%. Tại Việt Nam, táo Nhật thường được nhập khẩu về từ tháng 12 hàng năm và được bày bán đến dịp Tết, rất được khách hàng Việt ưa chuộng vì vẻ ngoài to, bắt mắt, vị ngon và thanh, thích hợp làm quà tặng biếu và chưng mâm ngũ quả dịp Xuân về!</p>
          <div class="align-center">
            <router-link to="/tao-aomori#tao-aomori" class="btn">
              Xem thêm
            </router-link>
          </div>
        </div>
        <img :src="require(`@/assets/${img.url}`)" :alt="img.alt">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AomoriApple',
  props: ['img'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
  
  section.container-fluid{
    color: $c2;

  }
  .content-bottom{
    display: flex;
    justify-content: space-between;
    gap: 5%;

    img{
      flex-grow: 0;
      flex-shrink: 0;
      width: 45%;
      height: auto;
      margin-bottom: 8.125rem;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .content-text{
      width: 50%;
      font-size: 1.25rem;
      text-align: left;

      li{
        list-style-position: inside;
      }
    }
    .align-center{
      text-align: center;
    
      .btn{
        margin-top: 3rem;
        text-align: center;
      }
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .content-bottom {

      .content-text{
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 750px){
    .content-bottom{
      flex-direction: column-reverse;

      img{
        width: 100%;
        margin-bottom: 2rem;
      }

      .content-text{
        width: 100%;
        font-size: 1rem;
      }
    }
  }

</style>