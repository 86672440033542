<template>
  <section class="container-fluid" id="ve-tinh-aomori">
    <div class="container-L">
      <h2>TỈNH AOMORI NHẬT BẢN</h2>
      <div class="content-bottom">
        <div class="row">
          <img class="img-m" :src="require(`@/assets/${img.main.url}`)" :alt="img.main.alt">
          <div class="content-text">
            <p>Tỉnh Aomori thuộc vùng Tohoku, nằm ở phía Bắc Nhật Bản. Với đặc điểm khí hậu lạnh quanh năm, mùa đông tuyết phủ dày đặc, mùa hè mát mẻ hơn, được ưu ái bởi thiên nhiên phong phú, có nhiều món ăn ngon, dãy núi tuyết và các địa điểm thăm quan hoa anh đào đẹp thu hút khách du lịch hàng năm.</p>
            <p>Đặc biệt, nhờ vào khí hậu có phần khắc nghiệt đã tôi luyện người nông dân tỉnh Aomori phát triển kỹ thuật trồng táo qua hơn 140 năm, tạo nên các loại Táo nổi tiếng khắp cả nước và trên thế giới, với hương vị thơm ngon, quả to chín mọng, dinh dưỡng.</p>
          </div>
        </div>
        <img v-for="img in img.other" :key="img.id" class="img-s" :src="require(`@/assets/${img.url}`)" :alt="img.alt">
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AboutAomori',
  props: ['img'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  #ve-tinh-aomori{
    background-color: $bgc1;
  }
  
  .content-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 5%;
    row-gap: 50px;

    .img-m{
      float: left;
      margin: 0 3rem 3rem 0;
      width: 40%;
      height: auto;
      object-fit: contain;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .content-text{
      width: 100%;
      font-size: 20px;
      text-align: left;

    }
    .img-s{
      width: 30%;
      height: 230px;
      object-fit: cover; 
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .content-bottom .row{
      .img-m{
        margin: 0 1rem 1rem 0;
      }
      .content-text{
        width: 100%;
        font-size: 1rem;
      } 
    }
  }
  @media screen and (max-width: 750px){
    .content-bottom{
      flex-direction: column;
      
      .row{
        display: flex;
        flex-wrap: wrap;
        
        .img-m{
          float: none;
          width: 100%;
          margin-bottom: 2rem;
        }
        .content-text{
          width: 100%;
          font-size: 1rem;
        }
      }

      .img-s{
        display: none;
      }
    }
  }

</style>