<template>
  <div class="container-fluid">
    <div class="container-L">
      <h2>Báo Chí và Khách Hàng</h2>
      <div class="row-focus">
        <ArticleCard v-for="article in articles" :key="article.id" :article="article" class="article-card" />      
        <TestimonialCard v-for="testimonial in testimonials" :key="testimonial.id" :testimonial="testimonial" class="testimonial-card" />      
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialCard from '@/components/home/TestimonialCard.vue';
import ArticleCard from '@/components/home/ArticleCard.vue';

export default {
  name: 'TestimonialSec',
  components: {
    TestimonialCard,
    ArticleCard
  },
  props: ['testimonials', 'articles'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .container-fluid{
    background-color: $bgc1;
  }
  .container-L{
    padding-bottom: 150px;
  }
  .row-focus{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;

    .testimonial-card,
    .article-card{
      width: calc((100% - 30px) / 2);
    }
  }  

  // media
  @media screen and (max-width: 750px){
    .row-focus .testimonial-card,
    .row-focus .article-card{
      width: 100%;
      max-width: 400px;
      margin: auto;
    }
  }

</style>