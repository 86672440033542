<template>
  <div class="article-card">
    <div class="card-img">
      <img :src="require(`@/assets/${article.img}`)" :alt="article.imgAlt">
    </div>
    <div class="card-content">
      <a class="card-content-wrapper" :href="article.url" target="_blank">
        <img class="logo" :src="require(`@/assets/${article.logo}`)" :alt="article.logoAlt">
        <h4>{{article.title}}</h4>
        <p>xem bài viết</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: ['article'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .article-card{
    display: flex;
    padding: 10px;
    background-color: $c3;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    .card-img{
      flex-shrink: 0;
      flex-basis: 40%;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-content{
      
      .card-content-wrapper{
        padding: 0px 24px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;

        .logo{
          max-width: 40%;
          height: 2rem;
          margin-right: auto;
          margin-bottom: 1rem;
        }
        h4{
          font-size: 1.1rem;
          margin-bottom: 1.5rem;
          color: $c2;
          font-weight: 500;
        }
        p{
          margin: auto 0 0 auto;
          text-decoration: underline;
          color: $c4;
        }
        @media (hover: hover) {
          &:hover h4{
            text-decoration: underline;
          }
          &:hover p{
            color: $c4-light;
            font-weight: 500;
          }
        }
      }
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .article-card{
      flex-direction: column;

      .card-img{
        height: 200px;
        flex-basis: 200px;

        // img{
        //   height: 100%;
        //   object-fit: cover;
        // }
      }
      .card-content{
        margin-top: 30px;
        height: 100%;

        h4{
          font-size: 1.25rem;
        }
      }
    }
  }

  // @media screen and (max-width: 500px){
  //   .article-card .card-content blockquote{
  //     font-size: 1.1rem;
  //   }
  // }

</style>