<template>
  <footer class="container-fluid">
    <div class="container-L">
      <router-link to="/">
          <img class="logo" src="@/assets/logo-w.png" alt="logo">
        </router-link>
      <div class="footer-center">
        <ul>
          <li>
            <router-link :to="{ name: 'apple'}">Táo Aomori</router-link>
          </li>
          <li>
            <router-link to="/#ve-tinh-aomori" class="active-disabled">Về tỉnh Aomori</router-link>
          </li>
          <li>
            <router-link to="/#mua-tao-o-dau" class="active-disabled">Mua táo ở đâu</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'recipes'}">Công thức và Mẹo hay</router-link>
          </li>
        </ul>
      </div>
      <SocialsFooter class="socials"/>
    </div>
  </footer>
</template>

<script>
import SocialsFooter from '@/components/SocialsFooter.vue';

export default {
  name: 'Footer',
  components: {
    SocialsFooter,
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .container-fluid{
    background-color: $c1;
    color: $c3;
  }

  .container-L{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 60px;

    .logo{
      height: 150px;
      width: 150px;
    }
    .footer-center li {
      list-style: none;
      line-height: 2rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;

      @media (hover:hover) {
        & a:hover{
          color: $c2;
          font-weight: bold;
        }
      }
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .container-L .footer-center{
      column-gap: 75px;
    }
  }

  @media screen and (max-width: 750px){
    .container-L{
      flex-direction: column;
      align-items: center;
      row-gap: 30px;

      & .footer-center{
        column-gap: 0;
        width: 100%;
        justify-content: space-around;
      }
    }
  }
</style>