<template>
  <section id="mua-tao-o-dau" class="container-L">
    <h2>Mua táo ở đâu</h2>
    <div class="content-bottom">
      <div v-for="store in stores" :key="store.id" class="store">
        <img :src="require(`@/assets/${store.url}`)" :alt="store.alt">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AomoriStores',
  props: ['stores'],
}
</script>

<style lang="scss" scoped>

  h2{
    text-transform: uppercase;
  }

  .content-bottom{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    column-gap: 30px;
    row-gap: 50px;

    .store{
      width: calc((100% - 60px) / 3);
      max-height: 200px;
      
      img{
        height: 100%;
        width: auto;
        max-width: 100%;
        margin: auto;
        object-fit: contain;
      }
    }
  }

  // media
  @media screen and (max-width: 750px){
    .content-bottom .store{
      max-height: 150px;
    }
  }

  @media screen and (max-width: 500px){
    .content-bottom{
      padding-top: 1rem;
      column-gap: 10px;
      row-gap: 40px;

      .store{
        width: calc((100% - 10px) / 2);
        max-height: 100px;
      } 
    }
  }

</style>