import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tao-aomori',
    name: 'apple',
    component: () => import(/* webpackChunkName: "aomoriApple" */'../views/AomoriAppleView.vue')
  },
  {
    path: '/cong-thuc-tao',
    name: 'recipes',
    component: () => import(/* webpackChunkName: "recipes" */'../views/RecipesView.vue'),
    meta: {
      title: 'Công thức và Mẹo hay'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: window.innerWidth>1300 ? 80 : 60,
      };
    } else {
      return savedPosition || {top:0}
    }
  },
})


// Add Meta Tags and title
router.beforeEach((to, from, next) => {
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  document.title = `${!to.meta.title ? "Táo Aomori | Táo ngon nhất thế giới" : `Táo Aomori | ${to.meta.title}`}`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
