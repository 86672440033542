<template>
  <div class="container-fluid">
    <div v-if="onMobile || !isMobile" class="carousel-container">
      <Splide :options="options" aria-label="autoplay-example" :has-track="false">
          <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev arrow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
              </svg>
            </button>
            <button class="splide__arrow splide__arrow--next arrow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
              </svg>
            </button>
          </div>
          <ul class="splide__pagination pagination"></ul>
          <SplideTrack>
            <SplideSlide v-for="image in carouselImgsfilter" :key="image.id">
              <img v-if="!isMobile || !image.urlS" :src="require(`@/assets/${image.urlXl}`)" :alt="image.alt">
              <img v-if="isMobile && image.urlS" :src="require(`@/assets/${image.urlS}`)" :alt="image.alt">
              <span v-if="label" class="label">{{image.name}}</span>
              <router-link v-if="image.href" :to="image.href" class="btn" :class="btnLeft==true ? 'btn-carousel-left' : 'btn-carousel-center'">
                Xem {{image.btnText ?? 'thêm'}}
              </router-link>
            </SplideSlide>
          </SplideTrack>
          <button v-if="!isMobile" class="splide__toggle toggle" type="button">
            <svg
              class="splide__toggle__play"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m22 12-20 11v-22l10 5.5z"/>
            </svg>
            <svg
              class="splide__toggle__pause"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m2 1v22h7v-22zm13 0v22h7v-22z"/>
            </svg>
          </button>
      </Splide>
    </div>
    <!-- mobile -->
    <div v-if="!onMobile && isMobile" class="container-fluid">
      <img :src="require(`@/assets/${carouselImgs[0].urlS}`)" :alt="carouselImgs[0].alt" class="img-mobile">
    </div>
    <!-- /mobile -->
  </div>
</template>

<script>
import '@splidejs/vue-splide/css';

import { Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import { defineComponent } from 'vue';

export default defineComponent( {
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
  },
  props: { 
    carouselImgs: Array,
    ratio: Number,
    socials: Boolean,
    onMobile: Boolean,
    btnLeft: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return{
      options: {
        type    : 'fade',
        speed   : 1500, 
        rewind  : true,
        autoplay: true,
        interval: 7000,
        width   : '100%',
        heightRatio: this.ratio,
        arrows: true,
      },
      isMobile: false,
      imgs: this.carouselImgs,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
   carouselImgsfilter() {
      return this.carouselImgs.filter(img => img.onCarousel != null ? img.onCarousel == 1 : img);
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    isMobile(newVal) {
      if (newVal === true) {
        this.options.heightRatio = 1.333;
        this.options.arrows = false;
      } else {
        this.options.heightRatio = this.ratio;
        this.options.arrows = true;
      }
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  }
} );
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .carousel-container{
    position: relative;

    img {
      width: 100%;
    }
    .label{
      position: absolute;
      left: 0;
      top: 5%;
      display: inline-block;
      padding: 10px 30px;
      background-color: $c1;
      color: $c3;
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
    .splide__arrow svg {
      width: 70px;
      height: initial;
      fill: #FAF9F6;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
    
      &:hover{
        fill: #fff;
      }
      
    }
    .arrow{
      border: none;
      background-color: transparent;
    }
    .splide__arrow--next {
      right: 3rem;
    }
    .splide__arrow--prev {
      left: 3rem;
    }
    .toggle{
      position: absolute;
      right: 2%;
      bottom: 25px;
      height: 16px;
      width: 16px;
      background: none;
      border: none;

      svg{
        fill: #ccc;
        opacity: 0.7;
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));

        &:hover{
          fill: #fff;
        }
      }
    }
    .btn-carousel-left{
      position: absolute;
      left: 20%;
      bottom: 20%;
    }
    .btn-carousel-center{
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 20%;
    }
  }
  .img-mobile{
    width: 100%;
  }

  // media
  @media screen and (max-width: 750px){
    .socials-box{
      display: none;
    }
    .btn-carousel{
      min-width: 185px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media screen and (max-width: 500px){
    .btn{
      padding: 10px 20px;
    }
  }
</style>

